import RenLink from "../shared/RenLink";
import Quote from "./quote";
import ImagePortableText from "./image.portableText";
import ImageCarousel from "../media/image.carousel";
import BlockContent from "@sanity/block-content-to-react";
import Table from "./table";
import Expandable from "./expandable";
import ExpandableMembers from "./expandableMembers";
import CardEmployeeListing from "../employee/card.employee.listing";
import PropTypes from "prop-types";
import TextTwoColumns from "./text.two.columns";
import { Advertisement } from "./advertisement";
import Video from "../media/video";
import CtaList from "./ctaList";
import DownloadList from "./downloadList";
import NbTextblock from "./nb-textblock";
import DescriptionList from "./description-list";
import Callout from "components/callout/callout";

const openInNewTab = {
  target: "_blank",
  rel: "noreferrer noopener",
};

const PortableText = ({
  blocks,
  ads = [],
  width = "",
  enableToCBot = false,
  showCategories = false,
  className = "",
  ...rest
}) => {
  const serializers = {
    types: {
      htmlSnapshot(props) {
        return (
          <PortableText blocks={props.node.snapshotReusable.portableText} />
        );
      },
      cta(props) {
        return <CtaList list={props.node.ctaList} />;
      },
      nbTextblock(props) {
        return (
          <NbTextblock
            portableText={props.node.portableText}
            color={props.node.color}
            align={props.node.align}
            className={width === "narrow" ? "o-wrapper--narrow" : ""}
          />
        );
      },
      callout(props) {
        return (
          <Callout
            title={props.node.title}
            intent={props.node.intention}
            className={width === "narrow" ? "o-wrapper--narrow" : ""}
          >
            <PortableText blocks={props.node.portableText} />
          </Callout>
        )
      },
      descriptionList(props) {
        return (
          <DescriptionList
            dl={props.node}
            className={width === "narrow" ? "o-wrapper--narrow" : ""}
          />
        );
      },
      download(props) {
        return <DownloadList list={props.node.downloadList} />;
      },
      imageCarousel(props) {
        return <ImageCarousel 
                  images={props.node.imageCarouselArray} 
                  aspectRatio={props.node.aspectRatio} 
                  className={width === "narrow" ? "o-wrapper--narrow" : ""} 
                />;
      },
      quote(props) {
        return (
          <Quote
            author={props.node.quoteAuthor}
            quote={props.node.quoteText}
            image={props.node.image}
            className={width === "narrow" ? "o-wrapper--narrow" : ""}
          />
        );
      },
      imageRich(props) {
        return (
          <ImagePortableText
            caption={props.node.caption}
            alt={props.node.alt}
            image={props.node.asset}
            textWidth={props.node.textWidth}
          />
        );
      },
      reference() {
        return null;
      },
      video(props) {
        return (
          <Video
            url={props.node.url}
            caption={props.node.caption}
            title={props.node.title}
          />
        );
      },
      advertisement(props) {
        return <Advertisement ads={ads} />;
      },
      personListing(props) {
        return (
          <CardEmployeeListing
            header={props.node.personListingTitle}
            persons={props.node.personListingList}
            showCategories={showCategories}
            className={width === "narrow" ? "o-wrapper--narrow" : ""}
          />
        );
      },
      expandable(props) {
        return (
          <Expandable
            title={props.node.expandableTitle}
            className={width === "narrow" ? "o-wrapper--narrow" : ""} >
            <PortableText blocks={props.node.expandableContent} />
          </Expandable>
        );
      },
      infoWithMembers(props) {
        return (
          <ExpandableMembers
            title={props.node.title}
            visibleInfo={props.node.visibleInfo}
            expandableTitle={props.node.expandableTitle}
            expandableInfo={props.node.expandableInfo}
            leader={props.node.leader}
            additionalParticipants={props.node.additionalParticipants}
            members={props.node.members}
            className={width === "narrow" ? "o-wrapper--narrow" : ""}
          />
        );
      },
      tableField(props) {
        return (
          <Table
            table={props.node}
            className={width === "narrow" ? "o-wrapper--narrow" : ""}
          />
        );
      },
      link(props) {
        return (
          <div className={width === "narrow" ? "o-wrapper--narrow" : null}>
            <RenLink
              href={props.node.url || props.node.internalPage}
              classes="c-button"
            >
              {props.node.text}
            </RenLink>
          </div>
        );
      },
      textTwoColumns(props) {
        return (
          <TextTwoColumns
            columns={[props.node.columnOne, props.node.columnTwo]}
            showCategories={showCategories}
          />
        );
      },
      block: props => {
        const { node } = props;
        // Protect against a blank node.style property
        const style = node.style || "normal";
        // find the heading blocks (style == h1,h2,h3 etc)
        if (enableToCBot && /^h\d/.test(style)) {
          const HeadingTag = style;
          const nodeId = `t-${node._key}`;

          return <HeadingTag id={nodeId} {...props} />;
        }

        if (width === "narrow") {
          return (
            <div className="o-wrapper--narrow">
              {BlockContent.defaultSerializers.types.block(props)}
            </div>
          );
        }
        return BlockContent.defaultSerializers.types.block(props);
      },
    },
    list({ type, children }) {
      switch (type) {
        case "number":
          return width == "narrow" ? (
            <ol className="o-wrapper--narrow">{children}</ol>
          ) : (
            <ol>{children}</ol>
          );
        default:
          return width == "narrow" ? (
            <ul className="o-wrapper--narrow">{children}</ul>
          ) : (
            <ul>{children}</ul>
          );
      }
    },
    marks: {
      link(props) {
        const {
          children = [],
          mark: { internalPage, url, text, target = false },
        } = props;
        return (
          <RenLink
            href={url || internalPage}
            classes="c-portableText__link"
            {...(target ? openInNewTab : {})}
          >
            {text ||
              children.reduce((acc, elem) => acc.concat(elem), "") ||
              "lenke"}
          </RenLink>
        );
      },
    },
  };

  return (
    <div className={`c-portableText ${className}`} {...rest}>
      <BlockContent blocks={blocks} serializers={serializers} />
    </div>
  );
};

PortableText.propTypes = {
  blocks: PropTypes.array,
  width: PropTypes.string,
  ads: PropTypes.array,
  wide: PropTypes.bool,
};

export default PortableText;
