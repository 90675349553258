const FileExcel = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1667 0C19.3944 0 19.5889 0.0833333 19.75 0.25C19.9167 0.411111 20 0.605556 20 0.833333V16.6667C20 16.8944 19.9167 17.0889 19.75 17.25C19.5889 17.4167 19.3944 17.5 19.1667 17.5H5.83333C5.60556 17.5 5.41111 17.4167 5.25 17.25C5.08333 17.0889 5 16.8944 5 16.6667V13.75H0.833333C0.605556 13.75 0.411111 13.6667 0.25 13.5C0.0833333 13.3389 0 13.1444 0 12.9167V4.58333C0 4.35556 0.0833333 4.16111 0.25 4C0.405556 3.83333 0.6 3.75 0.833333 3.75H5V0.833333C5 0.605556 5.08333 0.411111 5.25 0.25C5.41111 0.0833333 5.60556 0 5.83333 0H19.1667ZM5 9.81667L6.18333 12.0333H7.96667L5.98333 8.80833L7.93333 5.64167H6.21667L5.13333 7.64167L5.09167 7.70833L5.05833 7.78333L4.525 6.71667L3.975 5.64167H2.15833L4.05 8.825L1.98333 12.0333H3.78333L5 9.81667ZM11.875 16.25V13.75H6.25V16.25H11.875ZM11.875 12.5V9.375H10V12.5H11.875ZM11.875 8.125V5H10V8.125H11.875ZM11.875 3.75V1.25H6.25V3.75H11.875ZM18.75 16.25V13.75H13.125V16.25H18.75ZM18.75 12.5V9.375H13.125V12.5H18.75ZM18.75 8.125V5H13.125V8.125H18.75ZM18.75 3.75V1.25H13.125V3.75H18.75Z"
      fill="#0A6252"
    />
  </svg>
);

export default FileExcel;
