import RenLink from "../shared/RenLink";
import PropTypes from "prop-types";
import Image from "../media/image";

const CtaList = ({ list = [] }) => {
  return (
    <div className="c-portableText--cta-wrapper">
      {list.map(elem => (
        <Cta element={elem} key={elem._key} />
      ))}
    </div>
  );
};

const Cta = ({ element: { title, description, image, link } }) => {
  return (
    <div className="c-cta--wrapper">
      <div className="c-cta">
        <div className="c-cta__body">
          {image && image.asset && (
            <div className="c-cta__image-wrapper">
              <Image
                image={image}
                alt=""
                interceptBuilder={builder => builder.width(700).height(400)}
                className="c-cta__image"
              />
            </div>
          )}
          <div className="c-cta__content">
            {" "}
            <div>
              <h3 className="u-text--darkGreen u-text--bold">{title}</h3>
              <p>{description}</p>
            </div>
            {link &&
              (link.url ? (
                <RenLink
                  href={link.url}
                  className={`c-cta__border ${
                    link.button ? "c-button-dark" : "c-cta__contact"
                  }`}
                >
                  {link.text || "Se mer"}
                </RenLink>
              ) : (
                <RenLink
                  href={link.internalPage}
                  className={`c-cta__border ${
                    link.button ? "c-button-dark" : "c-cta__contact"
                  }`}
                >
                  {link.text || "Se mer"}
                </RenLink>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

Cta.propTypes = {
  element: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.object,
};

CtaList.propTypes = {
  list: PropTypes.array,
};

export default CtaList;
