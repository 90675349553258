import Image from "../media/image";

interface PersonImage {
  alt: string;
  SanityImageAssetDocument: any;
  url: any;
  path: any;
  size: any;
  assetId;
  mimeType;
  sha1hash;
  metadata;
  extension;
  _id;
  _rev;
  type, _createdAt, _updatedAt
}

interface Person {
  image?: any;
  email: string;
  title: string;
  workPosition: string;
  phoneNumber?: string;
  keywords?: string[];
}

interface CardEmployeeListingSingleProps {
  person: Person;
  showCategories?: boolean;
}

const CardEmployeeListingSingle = ({
  person,
  showCategories = false,
}: CardEmployeeListingSingleProps) => {
  const imageWidth = 120;
  const imageHeight = 140;

  return (
    <div className="c-card-employee-listing--card">
      <div className="c-card-employee-listing__head">
        {person.image && (
          <Image
            image={person.image}
            alt={person.image.alt}
            width={`${imageWidth}px`}
            height={`${imageHeight}px`}
            interceptBuilder={(builder: any) =>
              builder.width(imageWidth).height(imageHeight)
            }
          />
        )}
        <div className="c-card-employee-listing__credentials" id={person.email}>
          <h3 style={{ marginTop: 0, marginBottom: 0 }}>{person.title}</h3>
          <ul className="c-card-employee-listing__list">
            <li className="c-card-employee-listing__list-item">
              {person.workPosition}
            </li>
            <li className="c-card-employee-listing__list-item">
              E-post:{" "}
              <a className="c-link" href={`mailto:${person.email}`}>
                {person.email}
              </a>
            </li>
            {person.phoneNumber && (
              <li className="c-card-employee-listing__list-item">
                Mobil:{" "}
                <a className="c-link" href={`tel:${person.phoneNumber}`}>
                  {person.phoneNumber}
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
      {person.keywords && showCategories && (
        <>
          <p className="c-card-employee-listing__responsibilities-title o-label-uppercase">
            Ansvarsområder
          </p>
          <div className="c-card-employee-listing__body">
            <p className="c-card-employee-listing__responsibilities">
              {person.keywords.reduce(
                (accumulator, currentValue) =>
                  accumulator + " • " + currentValue
              )}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default CardEmployeeListingSingle;
