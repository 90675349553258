import PortableText from "./portableText";

const TextTwoColumns = ({ columns }) => (
  <section className="o-grid o-wrapper--narrow">
    {columns.map((column, index) => (
      <div
        className=" o-grid__item o-grid__item--half c-portableText"
        key={index}
      >
        <PortableText blocks={column} />
      </div>
    ))}
  </section>
);

export default TextTwoColumns;
