import Image from "../media/image";

const getImageDimensions = image => {
  const defaults = { width: 720, height: 800 };

  if (!image?.metadata) {
    return defaults;
  }
  return {
    width:
      image.metadata.dimensions.width > 720
        ? defaults.width
        : image.metadata.dimensions.width,
    height:
      image.metadata.dimensions.height > 800
        ? defaults.height
        : image.metadata.dimensions.height,
  };
};

// NOTE: This function presents images in both in RENblad and on the website
function ImagePortableText({
  image,
  caption = "",
  alt = "",
  textWidth,
  customBuilder,
}: {
  image: any;
  caption?: String;
  alt?: any;
  textWidth?: Boolean;
  customBuilder?: any;
}) {
  const { width, height } = getImageDimensions(image);
  const verifiedAltText = alt && alt.trim().length > 0 ? alt : "";

  return (
    <figure className="c-portableText--image-wrapper">
      <Image
        className="c-portableText--image"
        alt={verifiedAltText}
        image={image}
        interceptBuilder={builder => {
          if (textWidth) {
            return builder.width(width);
          }
          if (customBuilder) {
            return builder
              .width(customBuilder.width)
              .height(customBuilder.height)
              .fit("crop");
          } else return builder.height(height);
        }}
      />
      {caption && (
        <figcaption className="u-fontSmall u-margin--rm">{caption}</figcaption>
      )}
    </figure>
  );
}

export default ImagePortableText;
