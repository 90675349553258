const FileZip = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.83 2H17C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V13C20 13.7956 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16H3C2.20435 16 1.44129 15.6839 0.87868 15.1213C0.316071 14.5587 0 13.7956 0 13V3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H8C9.306 0 10.417 0.835 10.83 2ZM14 6V8H16V6H14ZM12 4V6H14V4H12ZM12 8V10H14V8H12ZM14 10V12H16V10H14ZM12 12V14H14V12H12Z"
      fill="#0A6252"
    />
  </svg>
);

export default FileZip;
