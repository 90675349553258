import CardEmployeeListingSingle from "./card.employee.listing.single";
import React from "react";

interface Person {
  image?: {
    alt: string;
  };
  title: string;
  workPosition: string;
  email: string;
  phoneNumber?: string;
  keywords?: string[];
}

interface CardEmployeeListingProps {
  persons: Person[];
  header?: string;
  showCategories?: boolean;
  className?: string;
}

const CardEmployeeListing = ({
  persons,
  header,
  showCategories = false,
  className,
}: CardEmployeeListingProps) => (
  <div className={className}>
    {header && <h2>{header}</h2>}
    <div className="u-padding-top-bottom--large c-card-employee-listing">
      {persons &&
        persons.map(person => (
          <CardEmployeeListingSingle
            person={person}
            key={person.title}
            showCategories={showCategories}
          />
        ))}
    </div>
  </div>
);

export default CardEmployeeListing;