import RenLink from "../shared/RenLink";
import PropTypes from "prop-types";
import downloadLinkFromFileType from "../../helpers/downloadLinkFromFileType.ts";
import Image from "../media/image";

const DownloadList = ({ list = [] }) => {
  return (
    <div className="c-portableText--download-wrapper">
      {list.map(elem => (
        <Download element={elem} key={elem._key} />
      ))}
    </div>
  );
};

const Download = ({ element: { title, description, image, link } }) => {
  let downloadLink = downloadLinkFromFileType(link.type);

  return (
    <div className="c-download--wrapper">
      <div className="c-download">
        <div className="c-download__body">
          {image && (
            <div className="c-download__image-wrapper">
              <Image
                image={image}
                alt=""
                interceptBuilder={builder => builder.width(200)}
                className="c-download__image"
              />
            </div>
          )}
          <div className="c-download__content">
            {" "}
            <div>
              <h3 id={`download-list-${title}`}>{title}</h3>
              <p>{description}</p>
            </div>
            {link && (
              <RenLink href={link.url} target="ren-download">
                <div className="c-download__link-and-icon">
                  <span className={`c-download__border c-download__contact`}>
                    {link.text || "Last ned " + downloadLink.text}
                  </span>
                  <downloadLink.icon />
                </div>
              </RenLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Download.propTypes = {
  element: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.object,
};

DownloadList.propTypes = {
  list: PropTypes.array,
};

export default DownloadList;
