import { any } from "prop-types";
import React from "react";
import FileIcon from "../components/icons/file-download";
import ExcelFileIcon from "../components/icons/file-excel";
import PdfFileIcon from "../components/icons/file-pdf";
import WordFileIcon from "../components/icons/file-word";
import ZipFileIcon from "../components/icons/file-zip";

interface DownloadLink {
  icon: React.FC<any>;
  text: string;
}

const downloadLinkFromFileType = (fileType: string): DownloadLink => {
  // RENBLAD has file types such as "pdf", "doc", "docx", "xlsx", "docm", "xls", "zip", "xlsm"
  // schemas/fields/download.js uses dropdown-menu with predefined file types: "excel", "word", "pdf", "zip"

  switch (fileType) {
    case "excel":
    case "xls":
    case "xlsx":
    case "xlsm":
      return {
        icon: ExcelFileIcon,
        text: "Excelfil",
      };

    case "word":
    case "doc":
    case "docx":
    case "docm":
      return {
        icon: WordFileIcon,
        text: "Wordfil",
      };

    case "zip":
      return {
        icon: ZipFileIcon,
        text: "Zipfil",
      };

    case "pdf":
      return {
        icon: PdfFileIcon,
        text: "PDF",
      };

    default:
      return {
        icon: FileIcon,
        text: "fil",
      };
  }
};

export default downloadLinkFromFileType;
