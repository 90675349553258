import Image from "../media/image";
import PropTypes from "prop-types";

const Quote = ({ quote, author, image, grey, className = "" }) => (
  <div className={className + " u-margin-top-bottom--large"}>
    <div className={grey ? "c-quote u-bg--lightgrey" : "c-quote"}>
      <div className="c-quote__body">
        <div className="u-text--darkGreen c-quote__content">
          <blockquote className="c-quote__icon">
            {quote && <p className="c-quote__text">{quote}</p>}
            {author && <p>{author}</p>}
          </blockquote>
        </div>
      </div>
      {image && (
        <Image
          className="u-bg--white c-quote__image"
          image={image}
          interceptBuilder={builder => builder.width(400)}
          alt={image.alt}
        />
      )}
    </div>
  </div>
);

Quote.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.object,
  grey: PropTypes.bool,
  className: PropTypes.string,
};

export default Quote;
