type Props = {
  table: any;
  className?: string;
};

const Table = ({ table, className = "" }: Props) => {
  if (
    !table ||
    !table.table ||
    !table.table.rows ||
    table.table.rows.length < 1
  ) {
    return null; // Don't render anything if there is no table
  }

  // Check if table has RichText elements
  if (
    table.table.rows.find(
      row => row.cells.filter(cell => typeof cell === "object").length > 0
    )
  ) {
    return (
      <>
        En feil har skjedd. Kontakt REN med følgende feilmelding: Tabeller
        støtter ikke riktekst enda
      </>
    );
  }

  return (
    <div
      className={
        table.useNewStyle
          ? "c-table " + className
          : "c-table-old o-wrapper--medium "
      }
    >
      <h3 id={`table-${table._key}`} className="c-table-heading">
        {table.tableTitle}
      </h3>

      {/* First row loop makes head elements */}
      <table>
        <tbody>
          {table.table.rows.map((tableRow, rowIndex) => (
            <tr className="c-table--row" key={tableRow._key}>
              {tableRow.cells.map((tableCell, index) =>
                rowIndex == 0 && table.tableContainsHeader ? (
                  <th className="c-table__row--heading" key={tableCell}>
                    {tableCell}
                  </th>
                ) : (
                  <td className="c-table__row--body" key={tableCell}>
                    {tableCell}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default Table;
