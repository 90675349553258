import Expandable from "./expandable";
import PortableText from "./portableText";

type Member = {
  title: string;
  company: string;
};

type Person = {
  title: string;
};

const Leader = ({ leader }: { leader: Person }) => {
  if (!leader || !leader.title) {
    return null;
  }
  return (
    <p className={"c-expandable-leader"}>
      <strong>Leder:</strong> {leader.title}
    </p>
  );
};

const PersonList = ({ persons }: { persons: Array<Person> }) => {
  if (!persons || persons.length === 0) {
    return null;
  }

  // Sort alphabetically on title
  const sortedPersons = [...persons].sort((a, b) => {
    const aTitle = a.title?.trim() ?? "";
    const bTitle = b.title?.trim() ?? "";
    return aTitle.localeCompare(bTitle, undefined, { sensitivity: "base" });
  });

  return (
    <div className={"c-expandable-participants"}>
      <p>
        <strong>Øvrige deltakere fra REN:</strong>
      </p>
      <ul>
        {sortedPersons.map((person, _key) => (
          <li key={_key}>{person.title ?? ""}</li>
        ))}
      </ul>
    </div>
  );
};

const MemberList = ({ members }: { members: Array<Member> }) => {
  if (!members || members.length === 0) {
    return null;
  }

  // Sort alphabetically on company name, then on name
  const sortedMembers = [...members].sort((a, b) => {
    // Spread members to avoid mutating the original array
    const aCompany = a.company?.trim() ?? "";
    const bCompany = b.company?.trim() ?? "";
    const companyComparison = aCompany.localeCompare(bCompany, undefined, {
      sensitivity: "base",
    });

    if (companyComparison !== 0) {
      return companyComparison;
    }

    // Handle title comparison
    const aTitle = a.title?.trim() ?? "";
    const bTitle = b.title?.trim() ?? "";
    return aTitle.localeCompare(bTitle, undefined, { sensitivity: "base" });
  });

  return (
    <div className={"c-expandable-members"}>
      <p>
        <strong>Medlemmer:</strong>
      </p>
      <ul>
        {sortedMembers.map((member, _key) => (
          <li key={_key}>
            <strong>{member.company ?? ""}</strong> – {member.title ?? ""}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ExpandableMembers = ({
  title,
  visibleInfo,
  expandableTitle,
  expandableInfo,
  leader,
  additionalParticipants,
  members,
  className,
}: {
  title: string;
  visibleInfo: Array<any>;
  expandableTitle: string;
  expandableInfo: Array<any>;
  leader: Person;
  additionalParticipants: Array<Person>;
  members: Array<Member>;
  className: string;
}) => {
  return (
    <div className="o-wrapper--narrow">
      <div>
        <h3>{title}</h3>
        <PortableText blocks={visibleInfo} />
      </div>
      <Expandable title={expandableTitle} className={className}>
        <Leader leader={leader} />
        <PersonList persons={additionalParticipants} />
        <MemberList members={members} />
        <PortableText blocks={expandableInfo} />
      </Expandable>
    </div>
  );
};

export default ExpandableMembers;
