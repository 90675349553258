const FileWord = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.17 0C19.39 0 19.5867 0.0833333 19.76 0.25C19.92 0.41 20 0.603333 20 0.83V16.67C20 16.8967 19.92 17.09 19.76 17.25C19.5867 17.4167 19.39 17.5 19.17 17.5H5.83C5.61 17.5 5.41333 17.4167 5.24 17.25C5.08 17.09 5 16.8967 5 16.67V13.75H0.83C0.61 13.75 0.413333 13.67 0.24 13.51C0.08 13.3367 0 13.14 0 12.92V4.58C0 4.36 0.08 4.16333 0.24 3.99C0.413333 3.83 0.61 3.75 0.83 3.75H5V0.83C5 0.603333 5.08 0.41 5.24 0.25C5.41333 0.0833333 5.61 0 5.83 0H19.17ZM5.03 8.09L6.23 12.03H7.6L8.91 5.47H7.53L6.75 9.35L5.64 5.6H4.5L3.31 9.37L2.53 5.47H1.09L2.4 12.03H3.77L5.03 8.09ZM18.75 16.25V13.75H6.25V16.25H18.75ZM18.75 12.5V9.38H10V12.5H18.75ZM18.75 8.13V5H10V8.13H18.75ZM18.75 3.75V1.25H6.25V3.75H18.75Z"
      fill="#0A6252"
    />
  </svg>
);

export default FileWord;
