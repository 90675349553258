import PropTypes from "prop-types";
import getVideoId from "get-video-id";
import Script from "next/script";

const VimeoPlayer = ({ videoUrl, title, caption }) => (
  <>
    <div className="c-portableText__video-wrapper">
      <iframe
        title={title || ""}
        src={videoUrl}
        className="c-portableText__video"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
      <Script src="https://player.vimeo.com/api/player.js"></Script>
    </div>
    {caption && <p className="u-fontSmall u-margin--rm">{caption}</p>}
  </>
);

const Video = ({ url, caption, title, wrapper = true }) => {
  const input = getVideoId(url);
  const id = input.id;
  const service = input.service;
  const videoUrl = `https://player.vimeo.com/video/${id}`;

  if (service == "vimeo") {
    if (wrapper) {
      return (
        <div className="o-wrapper--medium u-margin-top-bottom--large">
          <VimeoPlayer videoUrl={videoUrl} title={title} />
        </div>
      );
    }

    return <VimeoPlayer videoUrl={videoUrl} title={title} />;
  }
};
Video.propTypes = {
  url: PropTypes.string,
  caption: PropTypes.string,
  title: PropTypes.string,
  wrapper: PropTypes.bool,
};
export default Video;
