import PortableText from "./portableText";
import { Fragment } from "react";

const DescriptionList = ({ dl, className }) => {
  let classes = "c-description-list";
  if (className) classes += " " + className;

  return (
    <div className={classes}>
      {dl.title && <h5 className={"c-description-list__title"}>{dl.title}</h5>}
      <dl>
        {dl.descriptions &&
          dl.descriptions.map((d, i) => {
            return (
              <Fragment key={d.key}>
                <dt>{d.title}</dt>
                <dd>
                  <PortableText blocks={d.portableText} />
                </dd>
              </Fragment>
            );
          })}
      </dl>
    </div>
  );
};

export default DescriptionList;
