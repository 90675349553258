import PortableText from "./portableText";

const NbTextblock = ({ portableText, color, align, className }) => {
  const colorClass = color ? "c-nb-textbox__" + color : "";
  const alignClass =
    align && align == "center" ? "c-nb-textbox__portable-text-center" : "";

  return (
    <div className={className + " c-nb-textbox " + colorClass + " "}>
      <PortableText
        blocks={portableText}
        className={"c-nb-textbox__portable-text " + alignClass}
      />
    </div>
  );
};

export default NbTextblock;
